
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import FlipCardNew from "@/components/FlipCardNew.vue";
import Counter from "@/components/Counter.vue";
import Dialog from "@/components/Dialog.vue";
import Toast from "@/components/Toast.vue";

// Utils
import utilities from "../utilities";

export default defineComponent({
  name: "AskCard",
  components: {
    Button,
    FlipCardNew,
    Counter,
    Dialog,
    Toast,
  },
  data() {
    return {
      token: "",
      cards: [] as any[any],
      step: 1,
      uploadButtontext: "Subir PDF",
      loading: false,
      loadingText: "Cargando...",
      cardPreview: false,
      showRemoveDialog: false,
      onConfirmRemove: Function as any,
      onCancelRemove: Function as any,
      currentFile: [] as any[any],
      basePrice: 0,
      subtotal: 0,
      products: [] as any[any],
      isHover: {} as any[any],
      validated: false,
    };
  },
  async mounted() {
    //check auth and get token
    let checkAuth = utilities.checkAuth("pedir-credencial") as any;
    Promise.resolve(checkAuth).then((data) => {
      this.token = data.token as any;
    });

    //get config
    let config = (await utilities.getItems(
      "config/1?fields=*",
      this.token
    )) as any;

    //base price for credential
    this.basePrice = config.credential_price;

    //get products
    let products = (await utilities.getItems("products?fields=*.*.*")) as any;
    this.products = products;

    //set default variation
    this.products.forEach((product: any, productIndex: number) => {
      this.products[productIndex].selectedVariation =
        this.products[productIndex].variations[0];
    });
    //validación credenciales ok
    let valid = await (await utilities.isValidated()).isValid;
    this.validated = valid;
  },
  methods: {
    createCredential: async function (e: any) {
      e.preventDefault();

      // get file input
      const input = e.target;

      // display loading
      this.loading = true;

      Array.from(input.files).reduce(async (previousPromise, file: any) => {
        await previousPromise;

        // set loading
        this.loadingText = "Subiendo Archivo";

        // upload file
        const fileUpload = utilities.uploadFile(file, this.token);

        let qrDecoded = Promise;

        await Promise.resolve(fileUpload).then(async (file) => {
          if (file.error) {
            this.loading = false;
            (this.$refs.toast as any).show("Hubo un error al subir el archivo");
            throw file.error;
          }

          // set loading text
          this.loadingText = "Extrayendo texto";

            // set loading text
            this.loadingText = "Decodificando QR";

            // start qr decoding
            qrDecoded = utilities.qrDecode(file.data.full_url) as any;

            await Promise.resolve(qrDecoded).then(async (value: any) => {
              if (value.error || !value.link) {
                this.loading = false;
                (this.$refs.toast as any).show("No se pudo detectar QR");
                throw value.error;
              }

              // set uploaded file data
              let uploadedFile = {} as any;

              console.log(value)

              uploadedFile.full_name = value.text[1].toUpperCase();
              uploadedFile.doc = value.text[8];
              uploadedFile.location = value.text[4];
              uploadedFile.street = value.text[6];
              uploadedFile.apt = value.text[10];
              uploadedFile.zip_code = value.text[12];
              uploadedFile.permit_id_nbr = value.text[14];
              uploadedFile.emission_date = value.text[16];
              uploadedFile.expiration_date = value.text[18];
              uploadedFile.plants_qty_nbr = value.text[21];
              uploadedFile.qr_link_string = value.link as any;
              uploadedFile.quantity = 1;
              uploadedFile.pdf = file.id;
              uploadedFile.item_price = this.basePrice;

              // uset loading
              this.loading = false;

              // set loading text
              this.loadingText = "Esperando verificación";

              // show preview
              this.showPreview(uploadedFile);
            });
            return Promise.resolve();
        })
      }, Promise.resolve());
    },
    showPreview: function (uploadedFile: Array<any>) {
      this.currentFile = uploadedFile;
      this.cardPreview = true;
    },
    verifyData: function (status: string) {
      this.currentFile["verification"] = status;
      this.cards.push(Object(this.currentFile));
      this.cardPreview = false;
      this.updateCart();
    },
    updateQuantity: function (quantity: number, fileIndex: number) {
      this.cards[fileIndex].quantity = quantity;
      this.updateCart();
    },
    updateCart: function () {
      this.subtotal = 0;
      this.cards.forEach((item: any) => {
        this.subtotal += this.basePrice * item.quantity;
      });
    },
    removeItem: function (index: number) {
      this.showRemoveDialog = true;

      this.onConfirmRemove = () => {
        this.cards.splice(index, 1);
        this.updateCart();
        this.showRemoveDialog = false;
      };

      this.onCancelRemove = () => {
        this.showRemoveDialog = false;
      };
    },
    nextStep(step: number) {
      // step 1
      if (step === 1) {
        if (this.cards.length > 0) {
          this.$store.commit("setItems", this.cards);
          this.step++;
        } else {
          (this.$refs.toast as any).show("No aregaste archivos");
        }
      }
    },
    setVariation(productIndex: number, variarionIndex: number) {
      this.products[productIndex].selectedVariation =
        this.products[productIndex].variations[variarionIndex];
    },
    async addProduct(product: any) {
      let valid = await (await utilities.isValidated()).isValid;
      const toast = this.$refs.toast as any;
      if (product.no_stock != null && product.no_stock === true) {
        toast.show("Lo sentimos, este producto no está disponible");
        return;
      }

      this.$store.commit("addProduct", product);
      toast.show("Agregaste un producto al carrito");

    },
    setHover(productIndex: number) {
      this.isHover[productIndex] = true;
    },
    unsetHover(productIndex: number) {
      this.isHover[productIndex] = false;
    },
  },
});
